import { useContext } from "react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  FontWeight,
  Glyph,
  Icon,
  JustifyItems,
  P,
  Text,
  TextAlign,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import ExitBRF from "src/components/shared/ExitBRF";
import { BANKID_INSTRUCTIONS_LINK } from "src/components/pages/AutogiroBankIdConnect/GettingBankIdInstructionsLink";

const BankIdAppNotFound = () => {
  const {
    payerTheme,
    billingRequest,
    billingRequestFlow,
    setBankAuthorisation,
    setError,
  } = useContext(GlobalState);

  const router = useRouter();

  return (
    <XYGrid justifyItems={JustifyItems.Center} rowGap={3}>
      <Icon
        name={Glyph.ExclamationCircle}
        size="60px"
        color={Color.Warning_300}
      />

      <Box
        maxWidth={400}
        layout="flex"
        flexDirection="column"
        alignItems={AlignItems.Center}
      >
        <P
          preset={TypePreset.Heading_03}
          textAlign={TextAlign.Center}
          spaceBelow={1.5}
        >
          <Trans id="autogiro-bankid.error.no-app-on-the-device.title">
            The BankID app couldn’t be found on your device
          </Trans>
        </P>

        <P preset={TypePreset.Body_02} textAlign={TextAlign.Center}>
          <Trans id="autogiro-bankid.error.no-app-on-the-device.description">
            Please install the app from your app store and get a BankID from
            your bank.
          </Trans>
        </P>

        <BrandedLink
          textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
          href={BANKID_INSTRUCTIONS_LINK}
        >
          <Text
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_02}
            decoration="underline"
          >
            <Trans id="autogiro-bankid.error.no-app-on-the-device.learn-more-link">
              Learn more here
            </Trans>
          </Text>
        </BrandedLink>
      </Box>

      <BrandedButton
        type="submit"
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        onClick={() => {
          setBankAuthorisation(null, () => {
            setError(undefined);

            router.push({
              pathname: "autogiro-bankid-connect",
              query: router.query,
            });
          });
        }}
        size={ButtonSize.Lg}
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
        rightIcon={Glyph.ArrowForward}
      >
        <Trans id="autogiro-bankid.error.try-again">Try again</Trans>
      </BrandedButton>

      {billingRequestFlow?.exit_uri && (
        <ExitBRF
          billingRequestFlow={billingRequestFlow}
          billingRequest={billingRequest}
          setError={setError}
          payerTheme={payerTheme}
        />
      )}
    </XYGrid>
  );
};

export default BankIdAppNotFound;
