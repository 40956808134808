import { useContext } from "react";
import {
  Box,
  ButtonSize,
  P,
  Space,
  ButtonLayout,
  ButtonVariant,
  FontWeight,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";

import BrandedButton from "../../BrandedComponents/BrandedButton";
import ExitBRF from "../../ExitBRF";

const BankAccountNotEligibleError = () => {
  const { payerTheme, billingRequest, billingRequestFlow, setError } =
    useContext(GlobalState);

  return (
    <Box>
      <P size={6} weight={FontWeight.SemiBold}>
        <Trans id="bank-account-not-eligible-error.title">
          We can&apos;t set up a payment agreement with the bank account details
          you&apos;ve provided
        </Trans>
      </P>
      <Space v={1} />
      <P size={3}>
        <Trans id="bank-account-not-eligible-error.try-another-bank-message">
          Please try using another bank account
        </Trans>
      </P>
      <Space v={1.5} />
      <BrandedButton
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        variant={ButtonVariant.PrimaryOnLight}
        onClick={() => setError()}
        size={ButtonSize.Lg}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      >
        <Trans id="bank-account-not-eligible-error.use-another-bank">
          Use another bank account
        </Trans>
      </BrandedButton>
      <Space v={2} />
      <ExitBRF
        billingRequestFlow={billingRequestFlow}
        billingRequest={billingRequest}
        setError={setError}
        payerTheme={payerTheme}
        fontWeight={FontWeight.SemiBold}
        buttonSize={ButtonSize.Md}
      />
    </Box>
  );
};

export default BankAccountNotEligibleError;
