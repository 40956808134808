import { useContext } from "react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { ButtonVariant, P, Space } from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";

import BrandedLink from "../../BrandedComponents/BrandedLink";
import GenericError from "../GenericError";

const errorCode = "billing_request_flow_resume_unauthorised";

const LinkNotWorkingError = () => {
  const header = i18n._(
    t({
      id: "link-not-working-error.header",
      message: "This link is no longer working",
    })
  );
  const { billingRequestFlow, payerTheme } = useContext(GlobalState);
  const merchantEmail =
    billingRequestFlow?.config?.merchant_contact_details?.email;
  const merchantName =
    billingRequestFlow?.config?.merchant_contact_details?.name;

  if (!merchantName || !merchantEmail) {
    return <GenericError message={header} errorCode={errorCode} />;
  }

  return (
    <GenericError message={header} errorCode={errorCode}>
      <P size={3}>
        <Trans id="link-not-working-error.message">
          Please contact {merchantName} to issue a new payment link.
        </Trans>
      </P>
      <Space v={1} />
      <BrandedLink
        textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
        href={`mailto:${merchantEmail}`}
        variant={ButtonVariant.InlineUnderlined}
      >
        {merchantEmail}
      </BrandedLink>
    </GenericError>
  );
};

export default LinkNotWorkingError;
