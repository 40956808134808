import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import GenericError from "../GenericError";

const LinkExpiredError = () => {
  const { i18n } = useLingui();

  return (
    <GenericError
      message={i18n._(
        t({
          id: "link-expired-error.error-message",
          message: "It looks like this link has expired",
        })
      )}
    />
  );
};

export default LinkExpiredError;
