import { useContext, useEffect } from "react";
import {
  AlignItems,
  Box,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  P,
  Space,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { useSegment } from "src/shared/Segment/useSegment";
import { TrackingEvents } from "src/common/trackingEvents";
import { GC_SUPPORT_EMAIL } from "src/common/consts";

import BrandedLink from "../../BrandedComponents/BrandedLink";

const GenericError = ({
  message,
  errorCode,
  children,
}: {
  message?: string;
  errorCode?: string;
  children?: React.ReactNode;
}) => {
  const { payerTheme, billingRequestFlow, billingRequestId } =
    useContext(GlobalState);
  const { sendEvent } = useSegment();
  const supportEmail =
    billingRequestFlow?.config?.merchant_contact_details?.email;
  const supportEmailHref = `mailto:${supportEmail ?? GC_SUPPORT_EMAIL}`;

  useEffect(() => {
    sendEvent(TrackingEvents.GENERICERROR_DISPLAY_GENERIC_ERROR_SCREEN);
  }, []);

  // We are hyphenating to improve readability of the reference number
  const hyphenateId = (id: string | undefined) => {
    return id?.replace(/(.{3})(.{5})(.{3})(.*)/, "$1-$2-$3-$4");
  };

  return (
    <Box
      layout="flex"
      width="100%"
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      flexDirection="column"
    >
      <Icon
        name={Glyph.ExclamationCircle}
        color={Color.Warning_300}
        size="52px"
      />
      <Space v={2} />
      <P size={6} weight={FontWeight.SemiBold}>
        {message ?? (
          <Trans id="generic-error.error-message">
            Oh no! It looks like something has gone wrong
          </Trans>
        )}
      </P>
      <Space v={2} />
      {!children ? (
        <P size={3} data-testid="generic-error-message">
          <Trans id="generic-error.contact-support-message">
            Please contact our{" "}
            <BrandedLink
              textColor={getBrandColorFor(
                BrandedComponentType.Link,
                payerTheme
              )}
              href={supportEmailHref}
              variant={ButtonVariant.InlineUnderlined}
            >
              support team
            </BrandedLink>{" "}
            if you need help.
          </Trans>
        </P>
      ) : (
        children
      )}
      {errorCode && (
        <>
          <Space v={4} />
          <Text size={1} color={ColorPreset.TextOnLight_03}>
            <Trans id="generic-error.error-code">Error code: {errorCode}</Trans>
          </Text>
        </>
      )}
      {billingRequestId && (
        <>
          <Space v={2} />
          <P>
            <Trans>Quote the billing reference:</Trans>{" "}
            <Text weight={FontWeight.SemiBold}>
              {hyphenateId(billingRequestId)}
            </Text>
          </P>
        </>
      )}
    </Box>
  );
};

export default GenericError;
