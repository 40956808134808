import { useContext, useEffect } from "react";
import { AlignItems, Box, H1, P, TextAlign } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Image } from "src/components/shared/Image";
import Layout from "src/components/shared/Layout";
import { REDIRECT_WAIT_PERIOD } from "src/common/consts";
import { GlobalState } from "src/state";

const BankAuthAlreadyCompleted = () => {
  // Users will be redirected to this error page if
  // another BankAuthorisation is completed via Plaid,
  // after already completing it successfully

  const { billingRequestFlow } = useContext(GlobalState);

  const redirectURI = billingRequestFlow?.redirect_uri;

  useEffect(() => {
    if (redirectURI) {
      const timeoutID = setTimeout(() => {
        // Auto redirect to the provided merchant url
        // after the wait period
        window.location.href = redirectURI;
      }, REDIRECT_WAIT_PERIOD);

      // Remove the timeout when leaving the page
      return () => {
        clearTimeout(timeoutID);
      };
    }

    return () => {};
  }, [redirectURI]);

  return (
    <Layout>
      <Box layout="flex" flexDirection="column" alignItems={AlignItems.Center}>
        <Box spaceBelow={2}>
          <Image
            name="ExclamationMarkCircle"
            alt="Exclamation mark icon"
            width={60}
            height={60}
          />
        </Box>
        <H1 size={4} weight={600} textAlign={TextAlign.Center} spaceBelow={1}>
          <Trans id="plaid-bank-auth-already-completed.error.title">
            This link has expired
          </Trans>
        </H1>
        <P size={3} textAlign={TextAlign.Center}>
          <Trans id="plaid-bank-auth-already-completed.error.message">
            Your ACH Debit Authorization has already been successfully set up.
          </Trans>
        </P>
      </Box>
    </Layout>
  );
};

export default BankAuthAlreadyCompleted;
