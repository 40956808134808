import { Trans } from "@lingui/macro";
import { FontWeight, Text } from "@gocardless/flux-react";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import { PayerThemeType } from "src/state";

export const BANKID_INSTRUCTIONS_LINK =
  "https://www.bankid.com/en/privat/skaffa-bankid";

export const GettingBankIdInstructionsLink = ({
  payerTheme,
}: {
  payerTheme?: PayerThemeType;
}) => {
  return (
    <BrandedLink
      textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
      href={BANKID_INSTRUCTIONS_LINK}
    >
      <Text weight={FontWeight.SemiBold} size={2}>
        <Trans id="autogiro-bankid-connect-page.link.bankid-usage">
          Don’t have BankID? Find out how to get one
        </Trans>
      </Text>
    </BrandedLink>
  );
};

export default GettingBankIdInstructionsLink;
