import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import RestartError from "../RestartError";

const SessionExpiredError = () => {
  const { i18n } = useLingui();

  return (
    <RestartError
      message={i18n._(
        t({
          id: "session-expired-error.error-message",
          message: "Your session has expired",
        })
      )}
    />
  );
};

export default SessionExpiredError;
