import { Box, FontWeight, JustifyContent, P } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

const PayerCountryNotSupportedError = () => {
  return (
    <>
      <Box justifyContent={JustifyContent.Center}>
        <P size={6} weight={FontWeight.SemiBold} spaceBelow={1}>
          <Trans id="payer-country-not-supported-error.unable_to_process_payment">
            We are unable to process your request.
          </Trans>
        </P>
      </Box>

      <P size={3} spaceBelow={1.5}>
        <Trans id="payer-country-not-supported-error.no_payment_support_from_country">
          Unfortunately, we&apos;re not able to support payments from the
          country where you are currently located. Apologies for any
          inconvenience caused.
        </Trans>
      </P>

      <P size={3} spaceBelow={1.5}>
        <Trans id="payer-country-not-supported-error.able_to_retry_in_24_hours">
          You&apos;ll be able to retry this payment again in 24 hours.
        </Trans>
      </P>
    </>
  );
};

export default PayerCountryNotSupportedError;
